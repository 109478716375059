import React from "react";
import { benefitsLogo, videoCover } from "../../../images/imagesList";
import { withRouter } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
import OverlayModal from "../OverlayModal/OverlayModal";
import { Grid } from "@material-ui/core";

class VideoPlayerModal extends React.Component {
  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <OverlayModal open={this.props.open} onClose={this.handleClose}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} className="text-center">
            <img
              src={benefitsLogo}
              alt="benefits logo"
              width={36}
              height={36}
            ></img>
          </Grid>

          <Grid item xs={12} className="text-center bold main-title">
            About the Precision Consumption Ecosystem
          </Grid>

          <Grid item xs={12}>
            <VideoPlayer
              poster={videoCover}
              src="https://storage.googleapis.com/lifenome-public-www/video/precision-consumption.mp4"
            />
          </Grid>
          {/* <div className="text-area-video-modal">
            <div className="about-modal-text-header-big">
              <span className="bold">
                About the Precision Consumption Ecosystem
              </span>
            </div>

            <VideoPlayer
              poster={videoCover}
              src="https://storage.googleapis.com/lifenome-public-www/video/precision-consumption.mp4"
            />
          </div> */}
        </Grid>
      </OverlayModal>
    );
  }
}

export default withRouter(VideoPlayerModal);
