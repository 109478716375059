import React from "react";
import Stepper from "../shared/Stepper/Stepper";
import { withRouter } from "react-router-dom";
import "./Header.css";
import { pceLogo } from "../../images/iconList";
import AboutModal from "./AboutModal";
import StayUpdatedModal from "./StayUpdatedModal";
import { Desktop, Mobile } from "../../helpers/Responsive";
import { ROUTE_RETAIL_EXPERIENCE } from "../../routes";
import CartHeader from "../pages/RetailExperienceCart/CartHeader";

class Header extends React.Component {
  state = {
    openAboutModal: false,
    openStayUpdatedModal: false,
    hideHeader: false
  };

  handleCloseModal = () => {
    this.setState({
      hideHeader: false,
      openAboutModal: false,
      openStayUpdatedModal: false
    });
  };

  componentDidMount() {
    this.setState({ hideHeader: this.props.hideHeader });
  }

  showStayUpdated() {
    this.setState({
      openStayUpdatedModal: true,
      openAboutModal: false
    });
  }

  render() {
    return (
      <div
        className={
          "pceheader fixed-top" +
          (this.props.whiteBackground ? " white-bg" : " ")
        }
      >
        <AboutModal
          open={this.state.openAboutModal}
          closeModal={this.handleCloseModal}
        />
        <StayUpdatedModal
          open={this.state.openStayUpdatedModal}
          closeModal={this.handleCloseModal}
        />
        {!this.state.hideHeader && (
          <div>
            <div className="headerRow">
              <div className="py-3 headerTitle">
                {this.props.showTitle && (
                  <div>
                    <Desktop>
                      <img src={pceLogo} height="36px" alt="PCI logo" />
                    </Desktop>
                    <Mobile>
                      <img src={pceLogo} height="36px" alt="PCI logo" />
                    </Mobile>
                  </div>
                )}
              </div>
              <Desktop>
                <div className="headerNavigation center">
                  {this.props.showStepper && (
                    <div className="inline-block">
                      <div className="stepperCard">
                        <Stepper activeStep={this.props.activeStep} />
                      </div>
                      <div className="stepperTitle inline-block">
                        <p className="pt-2">{this.props.type}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Desktop>
              <div className="py-3 header-navigation-left">
                {this.props.showNavigation && (
                  <div className="headerText">
                    <span
                      onClick={() => {
                        this.setState({
                          openAboutModal: true,
                          openStayUpdatedModal: false
                        });
                      }}
                      className="px-2"
                    >
                      About
                    </span>
                    <span onClick={() => this.showStayUpdated()}>
                      Stay Updated
                    </span>
                  </div>
                )}
              </div>
            </div>
            <Mobile>
              <div className="headerNavigation center">
                {this.props.showStepper && (
                  <div>
                    <div className="stepperCard">
                      <Stepper activeStep={this.props.activeStep} />
                    </div>
                    <div className="stepperTitle inline-block">
                      <p className="pt-2">{this.props.type}</p>
                    </div>
                  </div>
                )}
              </div>
            </Mobile>
            {this.props.showShoppingSection && (
              <>
                <Desktop>
                  <div className="back-option flex">
                    <div
                      className="back-image v-center"
                      onClick={() =>
                        this.props.history.push({
                          pathname: ROUTE_RETAIL_EXPERIENCE
                        })
                      }
                    />
                    <div
                      className="back-text v-center"
                      onClick={() =>
                        this.props.history.push({
                          pathname: ROUTE_RETAIL_EXPERIENCE
                        })
                      }
                    >
                      Back to Shopping Preferences
                    </div>
                  </div>
                </Desktop>
                <div>
                  {this.props.cartHeaderImage && (
                    <CartHeader
                      image={this.props.cartHeaderImage}
                      type={this.props.type}
                      optionSelectClick={this.props.onOptionSelectClick}
                      checkoutItems={this.props.checkoutItems}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Header);
