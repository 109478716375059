import React from 'react';
import './VideoPlayer.css';
import 'video-react/dist/video-react.css';
import { Player, BigPlayButton } from 'video-react';

class VideoPlayer extends React.Component {
  render() {
    return (
      <div className="rounded shadow">
        <Player
          playsInline
          autoPlay={this.props.autoplay}
          poster={this.props.poster}
          src={this.props.src}
        >
          <BigPlayButton position="center" />
        </Player>
      </div>
    );
  }
}

export default VideoPlayer;
