import React from "react";
import Header from "../../header/Header";
import Footer from "../../shared/Footer/Footer";
import Card from "@material-ui/core/Card";
import {
  ROUTE_RETAIL_EXPERIENCE,
  ROUTE_RETAIL_EXPERIENCE_CART,
  ROUTE_START_PAGE
} from "../../../routes";
import { withRouter, Redirect } from "react-router-dom";
import { retailerA, retailerB } from "../../../images/imagesList";
import BenefitResult from "./BenefitResult";
import { setStorageType } from "../../../helpers/clientStorageHelper";
import "./Benefits.css";
import {
  getShoppingCart,
  clearShoppingCart,
  setShoppingCart
} from "../../../helpers/clientStorageHelper";
import APIClient from "../../../services/APIClient";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {
  getUserAndCategory,
  setUserAndCategory
} from "../../../helpers/clientStorageHelper";
import { getCategoriesExplanations } from "../../../helpers/categoriesHelper.js";
import CartItem from "../RetailExperienceCart/CartItem";
import Snackbar from "@material-ui/core/Snackbar";

class Benefits extends React.Component {
  userAndCategory = JSON.parse(getUserAndCategory());

  state = {
    checkoutItems: [],
    checkoutItemsOposite: [],
    loaded: false,
    totalPoints: 0,
    opositePoints: 0,
    points: 0,
    opositeCategory: undefined,
    disabledCategory: undefined,
    traitsInCategory: [],
    traitsInopositeCategory: [],

    filteredItems: []
  };

  componentDidMount = () => {
    console.log(this.getUnmatchedTraits());
    if (
      this.userAndCategory &&
      this.userAndCategory.user &&
      this.userAndCategory.category
    ) {
      let opositeCategory = undefined;
      let disabledCategory = undefined;
      APIClient.getRecommendedLifestyle(
        this.userAndCategory.user.profile.profile_id,
        this.userAndCategory.category.unique_code
      )
        .then(response => {
          const traits = [];
          for (const ix in response) {
            traits.push(response[ix].dna.trait.name);
          }
          this.setState({ traitsInCategory: traits });
        })
        .then(() => APIClient.getRecommendedCategories())
        .then(response => {
          for (const ix in response) {
            if (
              response[ix].user.first_name ===
              this.userAndCategory.user.user.first_name
            ) {
              for (const ix2 in response[ix].categories) {
                if (
                  response[ix].categories[ix2].unique_code !==
                  this.userAndCategory.category.unique_code
                ) {
                  if (response[ix].categories[ix2].enabled) {
                    opositeCategory = response[ix].categories[ix2];
                  } else {
                    disabledCategory = response[ix].categories[ix2];
                  }
                }
              }
            }
          }

          this.setState({ opositeCategory, disabledCategory }, () => {
            this.showData();
          });
        })
        .then(() =>
          APIClient.getRecommendedLifestyle(
            this.userAndCategory.user.profile.profile_id,
            opositeCategory.unique_code
          )
        )
        .then(response => {
          const traits = [];
          for (const ix in response) {
            traits.push(response[ix].dna.trait.name);
          }
          this.setState({ traitsInopositeCategory: traits });
        })
        .catch(err => console.log(err));
    } else {
      this.props.history.push(ROUTE_START_PAGE);
    }
  };

  showData() {
    const st = getShoppingCart(
      this.userAndCategory.user.user.first_name +
        "-" +
        this.userAndCategory.category.unique_code
    );
    const stOposite = getShoppingCart(
      this.userAndCategory.user.user.first_name +
        "-" +
        this.state.opositeCategory.unique_code
    );

    if (st || stOposite) {
      let arr = [];
      let arrOposite = [];
      let points = 0;
      let pointsOposite = 0;
      let filtered = [];
      if (st) {
        const ch = JSON.parse(st);
        arr = ch.checkoutItems;
        points = ch.totalPoints;
        for (const ixFiltered in ch.filteredItems) {
          let found = false;
          for (const ixCheckout in arr) {
            if (ch.filteredItems[ixFiltered].id === arr[ixCheckout][0].id) {
              found = true;
            }
          }

          if (found === false) {
            filtered.push(ch.filteredItems[ixFiltered]);
          }
        }
      }

      if (stOposite) {
        const ch = JSON.parse(stOposite);
        arrOposite = ch.checkoutItems;
        pointsOposite = ch.totalPoints;
      }

      this.setState({
        filteredItems: filtered,
        checkoutItems: arr,
        checkoutItemsOposite: arrOposite,
        loaded: true,
        totalPoints: points + pointsOposite,
        opositePoints: pointsOposite,
        points: points
      });
    } else {
      this.props.history.push(ROUTE_START_PAGE);
    }
  }

  startOver = category => {
    setStorageType(category.unique_code);
    clearShoppingCart(
      this.userAndCategory.user.user.first_name + "-" + category.unique_code
    );
    const categoriesExplanations = getCategoriesExplanations(
      category,
      this.userAndCategory.categoriesExplanations
    );
    setUserAndCategory(
      JSON.stringify({
        user: this.userAndCategory.user,
        category: category,
        categoriesExplanations: categoriesExplanations
      })
    );
    this.props.history.push({
      pathname: ROUTE_RETAIL_EXPERIENCE,
      state: {
        openCarousel: true
      }
    });
  };

  goToCart = category => {
    setStorageType(category.unique_code);
    const categoriesExplanations = getCategoriesExplanations(
      category,
      this.userAndCategory.categoriesExplanations
    );
    setUserAndCategory(
      JSON.stringify({
        user: this.userAndCategory.user,
        category: category,
        categoriesExplanations: categoriesExplanations
      })
    );
    this.props.history.push({
      pathname: ROUTE_RETAIL_EXPERIENCE_CART
    });
  };

  hasProductWithTraitInShoppingCart = (trait, items) => {
    for (const i in items) {
      for (const j in items[i]) {
        for (const t in items[i][j].traits) {
          if (items[i][j].traits[t].name === trait) {
            return true;
          }
        }
      }
    }

    return false;
  };

  getProductScore = (trait, items) => {
    let score = 0;
    for (const i in items) {
      for (const t in items[i][0].traits) {
        if (items[i][0].traits[t].name === trait) {
          if (items[i][0].traits[t].score > score) {
            score = items[i][0].traits[t].score;
          }
        }
      }
    }

    return score * 100;
  };

  makeitemAdvices = item => {
    let advices = [];

    if (item.traits) {
      for (const ix in item.traits) {
        advices.push({
          name: item.traits[ix].name,
          percentage: item.traits[ix].score * 100
        });
      }
    }

    return advices;
  };

  filterItemsById = (items, id) => {
    let filteredList = items;

    filteredList = filteredList.filter(item => {
      return item.id === id;
    });

    return filteredList;
  };

  addToCheckoutCart = id => {
    let checkoutItems = this.state.checkoutItems;
    let newItem = this.filterItemsById(this.state.filteredItems, id);

    let index = checkoutItems.findIndex(item => item[0].id === id);
    var filteredWithoutNewItem = this.state.filteredItems.filter(function(
      item
    ) {
      return item.id !== id;
    });

    if (index === -1) {
      checkoutItems.push(newItem);

      this.setState({
        checkoutItems: checkoutItems,
        filteredItems: filteredWithoutNewItem
      });
    } else {
      let amount = newItem[0].amount++;
      this.setState({
        checkoutItems: this.state.checkoutItems.map(item =>
          item.id === id ? { ...item, amount } : item
        ),
        filteredItems: filteredWithoutNewItem
      });
    }

    this.onCheckout();
    this.setState({ showAddedToCartSnackbar: true });
  };

  onCheckout = () => {
    const st = getShoppingCart(
      this.userAndCategory.user.user.first_name +
        "-" +
        this.userAndCategory.category.unique_code
    );

    if (st) {
      if (st) {
        const ch = JSON.parse(st);
        ch.filteredItems = this.state.filteredItems;
        ch.checkoutItems = this.state.checkoutItems;

        setShoppingCart(
          this.userAndCategory.user.user.first_name +
            "-" +
            this.userAndCategory.category.unique_code,
          JSON.stringify(ch)
        );
      }
    }
  };

  getUnmatchedTraits = () => {
    const totals = {};
    if (
      this.state.checkoutItems == null ||
      this.state.checkoutItems.length > 0
    ) {
      for (const itemIx in this.state.checkoutItems) {
        const traits = this.state.checkoutItems[itemIx][0].traits;
        for (const traitIx in traits) {
          if (totals[traits[traitIx].name] == null) {
            totals[traits[traitIx].name] = 0;
          }

          if (traits[traitIx].score > totals[traits[traitIx].name]) {
            totals[traits[traitIx].name] = traits[traitIx].score;
          }
        }
      }

      // if there is trait which isn't matched in any of the checkout items, we will set the score of that trait to 0
      for (const ix in this.state.traitsInCategory) {
        if (totals[this.state.traitsInCategory[ix]] == null) {
          totals[this.state.traitsInCategory[ix]] = 0;
        }
      }
    } else {
      for (const ix in this.state.traitsInCategory) {
        totals[this.state.traitsInCategory[ix]] = 0;
      }
    }

    let unmatchedStrs = [];
    for (const key in totals) {
      if (totals[key] < 1.0) {
        unmatchedStrs.push(key);
      }
    }
    return unmatchedStrs.join(", ");
  };

  getSortedProducts() {
    let items = this.state.filteredItems;
    items.sort(function(a, b) {
      return parseFloat(b.totalScore) - parseFloat(a.totalScore);
    });
    return items;
  }

  render() {
    if (this.props.location.state === undefined) {
      return <Redirect to={ROUTE_RETAIL_EXPERIENCE} />;
    }

    return (
      <div className="benefits">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.showAddedToCartSnackbar}
          key={"addedSnackbar"}
          onClose={() => this.setState({ showAddedToCartSnackbar: false })}
          message="Added to shopping cart!"
          autoHideDuration={3000}
        />

        {this.userAndCategory.category && (
          <Header
            showTitle
            showStepper
            showNavigation
            activeStep={3}
            type={this.userAndCategory.category.name}
            whiteBackground
          />
        )}
        {this.state.loaded === true && (
          <div className="benefits-container">
            <div className="upper-div">
              <div className="logo-div">
                <img
                  src={
                    this.props.location.state["retailer"] === "retailer_a.png"
                      ? retailerA
                      : retailerB
                  }
                  className="logo"
                  alt="retailer logo"
                ></img>
              </div>

              <div className="text-div">
                <span className="text-span">
                  {this.userAndCategory.user.user.first_name}'s purchases today.
                  <br />
                  Tailored for{" "}
                  {this.userAndCategory.user.basic_info.gender === "0"
                    ? "him"
                    : "her"}
                  .
                  <br />
                  Backed by science.
                </span>
                <br />
              </div>
            </div>
            <div className="bottom-div">
              <Grid container justify="center" className="cards-div">
                <Grid item className="benefits-c">
                  <Card className="center-card cards">
                    <div className="center-card-div">
                      <br />
                      <div className="benefit-results">
                        <div className="benefit-results-header">
                          <span>
                            {this.userAndCategory.user.user.first_name}'s{" "}
                            {this.userAndCategory.category.name}
                          </span>
                        </div>
                        <div className="benefit-results-content">
                          {this.state.traitsInCategory.map((trait, ix) => (
                            <BenefitResult
                              key={ix}
                              name={trait}
                              leftValue={
                                this.hasProductWithTraitInShoppingCart(
                                  trait,
                                  this.state.checkoutItems
                                )
                                  ? 10
                                  : 0
                              }
                              rightValue="10"
                              variant="custom"
                              score={this.getProductScore(
                                trait,
                                this.state.checkoutItems
                              )}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center">
                <div
                  style={{
                    maxWidth: "340px",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    lineHeight: "1.43",
                    textAlign: "center",
                    color: "#141414"
                  }}
                >
                  {this.userAndCategory.user.user.first_name}'s selection of
                  distinct products contain formulations that address each of{" "}
                  {this.userAndCategory.user.basic_info.gender === "0"
                    ? "his"
                    : "her"}{" "}
                  unique {this.userAndCategory.category.name.toLowerCase()}{" "}
                  needs as displayed in the chart above.
                </div>
              </Box>

              {this.state.loaded === true &&
                (this.getUnmatchedTraits() !== "" ||
                  this.state.checkoutItems.length === 0) && (
                  <div className="benefits-product-container">
                    <Box display="flex" justifyContent="center">
                      <div
                        style={{
                          maxWidth: "340px",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          lineHeight: "1.25",
                          textAlign: "center",
                          color: "#141414",
                          paddingTop: "25px",
                          paddingBottom: "30px"
                        }}
                      >
                        {this.userAndCategory.user.user.first_name} can improve{" "}
                        {this.userAndCategory.category.name.toLowerCase()} with
                        more products chosen just for{" "}
                        {this.userAndCategory.user.basic_info.gender === "0"
                          ? "his"
                          : "her"}
                        {" genetic predispositions:"}{" "}
                        {this.getUnmatchedTraits()}
                      </div>
                    </Box>
                    <Grid container justify="center">
                      <Grid item xs={12}>
                        <Grid container>
                          {this.getSortedProducts().map(
                            (item, index) =>
                              item.image && (
                                <Grid key={index} item xs={6} sm={3} md={3}>
                                  <CartItem
                                    index={index}
                                    key={item.id}
                                    id={item.id}
                                    points={item.points}
                                    brand={item.brand}
                                    description={item.name}
                                    goodFor={this.makeitemAdvices(item)}
                                    image={require(`../../../assets/images/product_images/${this.userAndCategory.category.unique_code}/${item.image}`)}
                                    addToCart={this.addToCheckoutCart}
                                  />
                                </Grid>
                              )
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
            </div>
          </div>
        )}
        <Footer
          nextPageTitle="Finish"
          previousPageTitle="Retail experience"
          previousPageRoute={ROUTE_RETAIL_EXPERIENCE}
          name={this.userAndCategory.user.user.first_name}
          hideLeftButton
          showButtonCenter
        />
      </div>
    );
  }
}

export default withRouter(Benefits);
