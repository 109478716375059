import React from "react";
import {
  danAvatar,
  sophieAvatar,
  benefitsLogo
} from "../../../images/imagesList";
import { withRouter } from "react-router-dom";
import {
  setStorageType,
  setStorageName,
  setUserAndCategory
} from "../../../helpers/clientStorageHelper";
import { ROUTE_START_PAGE, ROUTE_LIFESTYLE } from "../../../routes";
import "./Circle.css";
import APIClient from "../../../services/APIClient";
import OverlayModal from "../OverlayModal/OverlayModal";
import { Grid } from "@material-ui/core";

class Circle extends React.Component {
  state = {
    open: false,
    profileAvatar: "",
    opositePerson: undefined,
    opositeCategory: undefined
  };

  componentDidMount() {
    this.setState({
      profileAvatar:
        this.props.state.user.user.first_name === "Dan"
          ? danAvatar
          : sophieAvatar
    });
    APIClient.getRecommendedCategories()
      .then(response => {
        let opositePerson = undefined;
        let opositeCategory = undefined;
        for (const ix in response) {
          if (
            response[ix].user.first_name ===
            this.props.state.user.user.first_name
          ) {
            for (const ix2 in response[ix].categories) {
              if (
                response[ix].categories[ix2].unique_code !==
                  this.props.state.category.unique_code &&
                response[ix].categories[ix2].enabled
              ) {
                opositeCategory = response[ix].categories[ix2];
              }
            }
          } else {
            opositePerson = response[ix];
          }
        }
        this.setState({
          opositePerson: opositePerson,
          opositeCategory: opositeCategory
        });
      })
      .catch(err => console.log(err));
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  goToOpositeType = () => {
    setStorageType(this.state.opositeCategory.unique_code);
    setUserAndCategory(
      JSON.stringify({
        user: this.props.state.user,
        category: this.state.opositeCategory,
        categoriesExplanations: this.props.state.categoriesExplanations
      })
    );
    this.props.history.push({
      pathname: ROUTE_LIFESTYLE
    });
    window.location.reload();
  };

  goToOpositePersonCarousel = () => {
    setStorageName(this.state.opositePerson.user.first_name);
    this.props.history.push({
      pathname: ROUTE_START_PAGE
    });
  };

  render() {
    return (
      <div>
        {this.state.opositePerson && this.state.opositeCategory && (
          <OverlayModal open={this.state.open} onClose={this.handleClose}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} className="text-center">
                <img
                  src={benefitsLogo}
                  alt="benefits logo"
                  width={36}
                  height={36}
                ></img>
              </Grid>
              <Grid item xs={12} className="text-center main-title mx-2">
                You are viewing{" "}
                <span className="bold">
                  {this.props.state.user.user.first_name}'s
                </span>{" "}
                personalized consumption
                <span className="bold">
                  {" "}
                  {this.props.state.category.name.toLowerCase()}
                </span>{" "}
                experience.
              </Grid>
              <Grid item xs={12} className="text-center main-title mx-2 mt-3">
                Start a different experience?
              </Grid>
              <Grid item xs={12}>
                <hr className="line-big" />
              </Grid>

              <Grid item xs={12} sm={4} className="text-center">
                <button
                  className="modal-button"
                  onClick={() => this.goToOpositeType()}
                >
                  Start {this.props.state.user.user.first_name}'s{" "}
                  {this.state.opositeCategory.name}
                </button>
              </Grid>
              <Grid item xs={12} sm={4} className="text-center">
                <button
                  className="modal-button"
                  onClick={() => this.goToOpositePersonCarousel()}
                >
                  Start {this.state.opositePerson.user.first_name}
                </button>
              </Grid>
              <Grid item xs={12} sm={4} className="text-center">
                <button className="modal-button button-opacity">
                  Start Custom
                </button>
              </Grid>
              <Grid item xs={12} className="text-center bold my-3">
                OR
              </Grid>
              <Grid item xs={12} className="text-center">
                <span onClick={this.handleClose} className="button-bottom">
                  Go Back to {this.props.state.user.user.first_name}'s{" "}
                  {this.props.state.category.name.toLowerCase()}
                </span>
              </Grid>
            </Grid>
          </OverlayModal>
        )}

        <img
          alt="circle"
          src={this.state.profileAvatar}
          className="avatar-circle"
          onClick={() => {
            this.setState({ open: true });
          }}
        ></img>
      </div>
    );
  }
}

export default withRouter(Circle);
