import React from "react";
import "./CartItem.css";
import { dnaWhite } from "../../../images/imagesList";
import HistogramList from "./HistogramList";

export default class CartItem extends React.Component {
  render() {
    return (
      <div className="cart-item-container">
        <div className="cart-item center">
          <div
            className="item-image"
            style={{ backgroundImage: `url(${this.props.image})` }}
          >
            <div className="cart-item-points">
              <div className="points-icon" />
              <div className="points-ammount">
                <img src={dnaWhite} alt="dna" width="10px" height="10px" />
                {this.props.index + 1}
              </div>
            </div>
            <button
              onClick={() => {
                this.props.addToCart(this.props.id);
              }}
            >
              Add to cart
            </button>
          </div>
        </div>
        <div className="cart-item-description">
          <div className="description-title">{this.props.brand.name}</div>
          <div className="description-text">
            <p>{this.props.description}</p>
          </div>
          <div style={{ marginTop: "15px" }}>
            <HistogramList items={this.props.goodFor} />
          </div>
          {/*<div className="cart-item-advice">
            <div className="advice-title">Good for:</div>
            <div className="advice-decription">
              {this.props.goodFor.map((advice, ix) => (
                <span key={ix}>
                  {advice}
                  {ix < this.props.goodFor.length - 1 ? ", " : ""}
                </span>
              ))}
            </div>
              </div>*/}
        </div>
      </div>
    );
  }
}
