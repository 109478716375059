import React from "react";
import Circle from "../Circle/Circle";
import StayUpdatedModal from "../../header/StayUpdatedModal";
import { withRouter } from "react-router-dom";
import { Desktop, Mobile } from "../../../../src/helpers/Responsive";
import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";
import { getUserAndCategory } from "../../../helpers/clientStorageHelper";

class Footer extends React.Component {
  state = {
    hideHeader: false
  };

  handleCloseModal = () => {
    this.setState({
      openStayUpdatedModal: false
    });
  };

  render() {
    const userAndCategory = JSON.parse(getUserAndCategory());
    return (
      <div>
        {this.state.openStayUpdatedModal && (
          <StayUpdatedModal
            open={this.state.openStayUpdatedModal}
            closeModal={this.handleCloseModal}
          />
        )}
        {!this.props.hideFooter && (
          <div>
            <Desktop>
              <FooterDesktop
                {...this.props}
                state={userAndCategory}
                onStayUpdated={() =>
                  this.setState({ openStayUpdatedModal: true })
                }
              />
            </Desktop>
            <Mobile>
              <FooterMobile
                {...this.props}
                state={userAndCategory}
                onStayUpdated={() =>
                  this.setState({ openStayUpdatedModal: true })
                }
              />
            </Mobile>
          </div>
        )}
        <Circle state={userAndCategory} />
      </div>
    );
  }
}

export default withRouter(Footer);
