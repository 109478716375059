import React from "react";
import { closeButton } from "../../../images/imagesList";
import { withRouter } from "react-router-dom";
import "./OverlayModal.css";
import { Grid, Dialog } from "@material-ui/core";

class OverlayModal extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <Dialog {...this.props} open={this.props.open} onClose={this.handleClose}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <img
              width={44}
              height={44}
              src={closeButton}
              onClick={this.handleClose}
              className="overlay-modal-close-button"
              alt="close button"
            ></img>
          </Grid>
          <Grid item xs={12} className="mx-4 my-4">
            {this.props.children}
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default withRouter(OverlayModal);
