// Storage fields key names
const USER_NAME = "user_name";
const PRODUCTS_TYPE = "products_type";

// Session storage setter and getter helper methods
const setSessionStorageItem = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

const getSessionStorageItem = key => {
  return window.sessionStorage.getItem(key);
};

const removeSessionStorageItem = key => {
  window.sessionStorage.removeItem(key);
};

// Exported methods to use accross app

// USER_NAME
export const getStorageName = () => {
  return getSessionStorageItem(USER_NAME);
};

export const setStorageName = newValue => {
  setSessionStorageItem(USER_NAME, newValue);
};

export const removeStorageName = () => {
  removeSessionStorageItem(USER_NAME);
};

// PRODUCTS_TYPE
export const getStorageType = () => {
  return getSessionStorageItem(PRODUCTS_TYPE);
};

export const setStorageType = newValue => {
  setSessionStorageItem(PRODUCTS_TYPE, newValue);
};

export const removeStorageType = () => {
  removeSessionStorageItem(PRODUCTS_TYPE);
};

export const getShoppingCart = key => {
  return getSessionStorageItem(key);
};

export const setShoppingCart = (key, value) => {
  setSessionStorageItem(key, value);
};

export const clearShoppingCart = key => {
  removeSessionStorageItem(key);
};

export const getUserAndCategory = () => {
  return getSessionStorageItem("user_and_category");
};

export const setUserAndCategory = value => {
  setSessionStorageItem("user_and_category", value);
};
