import React from "react";
import "./CheckoutCart.css";
import CheckoutItem from "./CheckoutItem";
import { withRouter } from "react-router-dom";
import { ROUTE_BENEFITS } from "../../../routes";
import { Grid } from "@material-ui/core";
import { Desktop } from "helpers/Responsive";

class CheckoutCart extends React.Component {
  handleAddItem = id => {
    this.props.addItemCallback(id);
  };

  handleRemovedItem = id => {
    this.props.removeItemCallback(id);
  };

  getNumberOfItems = checkoutItems => {
    let c = 0;
    if (checkoutItems) {
      for (let itemsIx in checkoutItems) {
        if (checkoutItems[itemsIx].length > 0) {
          c += checkoutItems[itemsIx][0].amount;
        }
      }
    }

    return c;
  };

  render() {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Desktop>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="checkout-cart-header mb-2"
            >
              <Grid item className="checkout-cart-header-text ml-3">
                {this.props.name}'s Cart
              </Grid>
              <Grid item className="checkout-cart-header-text mr-3">
                {this.getNumberOfItems(this.props.checkoutItems)}{" "}
                {this.getNumberOfItems(this.props.checkoutItems) === 1
                  ? "item"
                  : "items"}
              </Grid>
            </Grid>
          </Grid>
        </Desktop>
        <Grid item xs={12} className="mx-2">
          <div>
            {this.props.checkoutItems.map(item => (
              <CheckoutItem
                key={item[0].id}
                id={item[0].id}
                description={item[0].name}
                image={require(`../../../assets/images/product_images/${this.props.careType}/${item[0].image}`)}
                healthyMePoints={item[0].points}
                amount={item[0].amount}
                addCallback={this.handleAddItem}
                removeCallback={this.handleRemovedItem}
              />
            ))}
          </div>
        </Grid>

        <Grid item xs={12} className="mx-2">
          <Grid
            className="mt-2 pt-2"
            container
            direction="row"
            justify="space-between"
            alignItems="baseline"
          >
            <Grid item xs={12}>
              <div className="checkout-button mt-2">
                <button
                  onClick={() => {
                    if (this.props.onCheckout) {
                      this.props.onCheckout();
                    }

                    this.props.history.push({
                      pathname: ROUTE_BENEFITS,
                      state: {
                        retailer: this.props.retailer,
                        totalPoints: this.props.totalPoints
                      }
                    });
                  }}
                >
                  Next: Check Out & See Benefits
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(CheckoutCart);
