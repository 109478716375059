export const getCategoriesExplanations = (category, categoriesExplanations) => {
  const pes = [];
  for (const ix in categoriesExplanations) {
    if (
      categoriesExplanations[ix].category.unique_code === category.unique_code
    ) {
      pes.push(categoriesExplanations[ix]);
    }
  }
  return pes;
};
