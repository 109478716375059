import React from "react";

import { Grid, Box } from "@material-ui/core";

import { ProgressBar } from "react-bootstrap";

export default function HistogramList(props) {
  return (
    <Box>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        {props.items.map((item, ix) => {
          return (
            <Grid item key={ix}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item style={{ width: "86px" }}>
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      color: "#141414"
                    }}
                  >
                    {item.name}
                  </span>
                </Grid>
                <Grid item>
                  <ProgressBar
                    variant="custom-histogram"
                    className="progress-bar-active"
                    now={item.percentage}
                    style={{ width: "60px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
