import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Circle from "@material-ui/icons/FiberManualRecord";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepLabel from "@material-ui/core/StepLabel";
import { withRouter } from "react-router-dom";
import {
  ROUTE_LIFESTYLE,
  ROUTE_DNA_PROFILE,
  ROUTE_RETAIL_EXPERIENCE,
  ROUTE_BENEFITS
} from "../../../routes";
import "./Stepper.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "0px",
    backgroundColor: "#eef2f5"
  },
  stepper: {
    padding: "0px"
  },
  step: {
    padding: "0px"
  },
  label: {
    fontSize: "12px"
  }
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  stepper: {
    padding: "0px"
  },
  step: {
    padding: "0px"
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(0,0,0) 0%, rgb(0,0,0) 50%, rgb(0,0,0) 100%)",
    color: "#000"
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(132,145,156) 0%, rgb(132,145,156) 50%, rgb(132,145,156) 100%)",
    color: "#84919c"
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <Circle />,
    2: <Circle />,
    3: <Circle />,
    4: <Circle />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

function getSteps() {
  return ["Lifestyle", "DNA Profile", "Retail Experience", "Benefits"];
}

function HorizontalNonLinearAlternativeLabelStepper(props) {
  const classes = useStyles();
  const steps = getSteps();

  const handleStep = step => () => {
    switch (step) {
      case 0:
        props.history.push(ROUTE_LIFESTYLE);
        break;
      case 1:
        props.history.push(ROUTE_DNA_PROFILE);
        break;
      case 2:
        props.history.push(ROUTE_RETAIL_EXPERIENCE);
        break;
      case 3:
        props.history.push(ROUTE_BENEFITS);
        break;
      default:
        props.history.push(ROUTE_LIFESTYLE);
        break;
    }
  };

  return (
    <div>
      <Stepper
        className={classes.stepper}
        alternativeLabel
        activeStep={props.activeStep}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepButton onClick={handleStep(index)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export default withRouter(HorizontalNonLinearAlternativeLabelStepper);
