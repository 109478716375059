import React from "react";
import Header from "../../header/Header";
import Profiles from "../Profiles/Profiles";
import ProfileCard from "../../shared/ProfileCard/ProfileCard";
import "./StartPage.css";
import { lifenomeLogo, wefLogo, ibmLogo } from "../../../images/imagesList";
import APIClient from "../../../services/APIClient";
import Grid from "@material-ui/core/Grid";

export default class StartPage extends React.Component {
  state = {
    predefinedUsers: undefined,
    availableCategories: undefined
  };

  componentDidMount() {
    APIClient.getRecommendedCategories()
      .then(response => {
        this.setState({ availableCategories: response });
      })
      .then(() => APIClient.getPredefinedUsersInfo())
      .then(response => {
        this.setState({ predefinedUsers: response.predefined_users }, () => {
          if (
            this.props.location.state &&
            this.props.location.state.openCarousel
          ) {
            if (
              this.props.name === this.state.predefinedUsers[0].user.first_name
            ) {
              this.refs.profiles.show(0);
            }
            if (
              this.props.name === this.state.predefinedUsers[1].user.first_name
            ) {
              this.refs.profiles.show(1);
            }
          }
        });
      })
      .catch(err => console.log(err));
  }

  calculateAge(birthYear) {
    return new Date().getFullYear() - birthYear;
  }

  render() {
    return (
      <div>
        <Profiles
          ref="profiles"
          predefinedUsers={this.state.predefinedUsers}
          categories={this.state.availableCategories}
        />
        <Header showNavigation />
        <div className="start-page-content center">
          <div className="start-page-title">
            <h1 className="bold">Precision Consumption Ecosystem</h1>
          </div>
          <div className="start-page-subtitle">
            <h2 className="center">
              Empowering consumers to securely leverage their unique biological,
              behavioral, and lifestyle data to personalize consumer products
              and services provided by some of the world’s leading brands and
              retailers
            </h2>
            <h3>
              Tap below to choose a precision consumption consumer journey.
            </h3>
          </div>
          <div className="start-page-profiles">
            <div className="profiles-container">
              <Grid container justify="center">
                {this.state.predefinedUsers &&
                  this.state.predefinedUsers.map((user_info, ix) => (
                    <Grid item key={"grid" + ix}>
                      <ProfileCard
                        profileImage={
                          APIClient.getApiUrl() +
                          user_info.profile.profile_img_url
                        }
                        profileText={
                          user_info.user.first_name +
                          ", " +
                          this.calculateAge(user_info.basic_info.birth_year)
                        }
                        location={
                          user_info.basic_info.city +
                          ", " +
                          user_info.basic_info.state +
                          ", " +
                          user_info.basic_info.country
                        }
                        name={user_info.user.first_name}
                        onClick={() => this.refs.profiles.show(ix)}
                      />
                    </Grid>
                  ))}

                <Grid item>
                  <ProfileCard profileImageText="Jamie is a 26-year-old who lives in Chicago, Illinois, USA and works as an accounting manager. She has an active lifestyle and abides by an omnivore diet." />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className="start-page-logos">
          <Grid container justify="center">
            <Grid item>
              <div className="start-page-logo-lifenome">
                <img src={lifenomeLogo} alt="LifeNome logo" />
              </div>
            </Grid>

            <Grid item>
              <div className="start-page-logo-wef">
                <img src={wefLogo} alt="WEF logo" />
              </div>
            </Grid>

            <Grid item>
              <div className="start-page-logo-ibm">
                <img src={ibmLogo} alt="IBM logo" />
              </div>
            </Grid>
          </Grid>

          <div className="clear" />
        </div>
      </div>
    );
  }
}
