import React from "react";
import "./ProfileItem.css";
import { withRouter } from "react-router-dom";

class ProfileItem extends React.Component {
  render() {
    return (
      <div
        className={"profile-item-image "}
        style={{
          backgroundImage: this.props.imageUrl
        }}
      >
        <div className="profile-item-text">
          <p>{this.props.text}</p>
        </div>
      </div>
    );
  }
}

export default withRouter(ProfileItem);
