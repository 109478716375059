import React from "react";
import "./CaruselItem.css";
import { withRouter } from "react-router-dom";
import {
  setStorageName,
  setStorageType
} from "../../../helpers/clientStorageHelper.js";
import Swiper from "react-id-swiper";
import { Desktop, Mobile } from "../../../../src/helpers/Responsive";
import APIClient from "../../../services/APIClient";
import { ROUTE_LIFESTYLE } from "../../../routes";
import { setUserAndCategory } from "../../../helpers/clientStorageHelper";

class CaruselItem extends React.Component {
  calculateAge(birthYear) {
    return new Date().getFullYear() - birthYear;
  }

  composeAddress(user) {
    return (
      user.basic_info.city +
      ", " +
      user.basic_info.state +
      ", " +
      user.basic_info.country
    );
  }

  renderCategoryButtons = () => {
    return (
      this.props.categories &&
      this.props.categories.map((category, ix) => (
        <button
          key={ix}
          disabled={!category.enabled}
          onClick={() => {
            setStorageName(this.props.user.user.first_name);
            setStorageType(category.name);
            setUserAndCategory(
              JSON.stringify({
                user: this.props.user,
                category: category,
                categoriesExplanations: this.props.categoriesExplanations
              })
            );

            this.props.history.push({ pathname: ROUTE_LIFESTYLE });
          }}
        >
          {category.name}
        </button>
      ))
    );
  };

  render() {
    const params = {
      slidesPerView: 2,
      centeredSlides: true,
      loop: false,
      direction: "vertical"
    };

    return (
      <div className="carusel-item-card">
        <div
          className="carusel-item-container"
          style={{
            backgroundImage: `url(${APIClient.getApiUrl() +
              this.props.user.profile.profile_img_url})`.replace(
              ".png",
              "-alt.png"
            ),
            maxHeight: "441px"
          }}
        >
          <div className="carusel-title">{this.props.user.user.first_name}</div>
          <div className="carusel-info">
            <div className="carousel-info-row">
              <div className="map-pin carousel-birthday"></div>
              <div>
                <p>
                  Age:{" "}
                  {this.calculateAge(this.props.user.basic_info.birth_year)}
                </p>
              </div>
              <div className="clear" />
            </div>

            <div className="carousel-info-row">
              <div className="map-pin carousel-map"></div>
              <div>
                <p>{this.composeAddress(this.props.user)}</p>
              </div>
              <div className="clear" />
            </div>

            <div className="carousel-info-row">
              <div className="map-pin carousel-work"></div>
              <div>
                <p>{this.props.user.profile.job_title}</p>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div className="carusel-item-images">
            {this.props.user.lifestyle &&
              this.props.user.lifestyle.map((userLifestyle, ix) => (
                <div
                  key={ix}
                  className={"carousel-image image-1-dan"}
                  style={{
                    backgroundImage: `url(${APIClient.getApiUrl()}/static/${
                      userLifestyle.unique_code
                    }.png)`
                  }}
                >
                  <div className="carusel-images-text">
                    <p>{userLifestyle.name}</p>
                  </div>
                </div>
              ))}

            <div className="clear" />
          </div>
          <div className="carusel-item-footer">
            <div className="buttons-title">
              Start {this.props.user.user.first_name}’s precision consumption
              experience with…
            </div>
            <div className="buttons-container">
              <Desktop>{this.renderCategoryButtons()}</Desktop>
              <Mobile>
                <Swiper {...params}>{this.renderCategoryButtons()}</Swiper>
              </Mobile>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CaruselItem);
