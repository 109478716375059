import React from "react";
import Header from "../../header/Header";
import CartItem from "./CartItem";
import CheckoutCart from "./CheckoutCart";
import Footer from "../../shared/Footer/Footer";
import { withRouter } from "react-router-dom";
import "./RetailExperienceCart.css";
import RetailExperienceModal from "./RetailExperienceModal";
import {
  getShoppingCart,
  setShoppingCart
} from "../../../helpers/clientStorageHelper";
import APIClient from "../../../services/APIClient";
import Grid from "@material-ui/core/Grid";
import { Desktop } from "../../../helpers/Responsive";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import { getUserAndCategory } from "../../../helpers/clientStorageHelper";
import Snackbar from "@material-ui/core/Snackbar";

class RetailExperienceCart extends React.Component {
  userAndCategory = JSON.parse(getUserAndCategory());

  constructor(props) {
    super(props);
    this.cartHeaderRef = React.createRef();
  }

  state = {
    cartItems: [],
    filteredItems: [],
    checkoutItems: [],
    checkoutCartData: [],
    brands: [],
    selectedBrand: undefined,
    price: 0,
    cartHeaderImage: "retailer_a.png",
    careType: "",
    totalPoints: 0,
    openModal: false /* Displaying modal removed in https://lifenome.atlassian.net/browse/PCM-92 */,
    hideHeader: true,
    loaded: false
  };

  componentDidMount = () => {
    const st = getShoppingCart(
      this.userAndCategory.user.user.first_name +
        "-" +
        this.userAndCategory.category.unique_code
    );
    if (st) {
      const persistedState = JSON.parse(st);
      this.setState({
        cartItems: persistedState.cartItems,
        filteredItems: persistedState.filteredItems,
        checkoutItems: persistedState.checkoutItems,
        checkoutCartData: persistedState.checkoutCartData,
        cartHeaderImage: persistedState.cartHeaderImage,
        careType: persistedState.careType,
        totalPoints: persistedState.totalPoints,
        brands: persistedState.brands,
        selectedBrand: persistedState.selectedBrand,
        price: persistedState.price,
        loaded: true
      });
    } else {
      this.setCartHeaderProps();
    }

    this.getItemsFromDatabase();
  };

  onCheckout = () => {
    const persistedState = {
      cartItems: this.state.cartItems,
      filteredItems: this.state.filteredItems,
      checkoutItems: this.state.checkoutItems,
      checkoutCartData: this.state.checkoutCartData,
      cartHeaderImage: this.state.cartHeaderImage,
      careType: this.userAndCategory.category.unique_code,
      totalPoints: this.state.totalPoints,
      brands: this.state.brands,
      selectedBrand: this.state.selectedBrand,
      price: this.state.price
    };

    setShoppingCart(
      this.userAndCategory.user.user.first_name +
        "-" +
        this.userAndCategory.category.unique_code,
      JSON.stringify(persistedState)
    );
  };

  setCartHeaderProps = () => {
    this.setState({
      price: this.userAndCategory.price,
      cartHeaderImage: this.userAndCategory.retailerImage,
      brands: this.userAndCategory.selectedBrands,
      selectedBrand: this.userAndCategory.selectedBrands[0],
      loaded: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      openModal: false
    });
  };

  getItemsFromDatabase = async () => {
    APIClient.getRecommendedProduct(
      this.userAndCategory.user.profile.profile_id,
      this.userAndCategory.category.unique_code
    )
      .then(response => {
        let filteredList = response;
        for (const ix in filteredList) {
          filteredList[ix].amount = 1;
        }

        filteredList = this.filterItemsByBrands(
          filteredList,
          this.state.brands
        );
        filteredList = this.filterItemsByPrice(filteredList, this.state.price);
        filteredList.sort(function(a, b) {
          return parseFloat(b.totalScore) - parseFloat(a.totalScore);
        });

        this.setState({
          cartItems: response,
          filteredItems: filteredList
        });
      })
      .catch(err => console.log(err));
  };

  makeitemAdvices = item => {
    let advices = [];

    if (item.traits) {
      for (const ix in item.traits) {
        advices.push({
          name: item.traits[ix].name,
          percentage: item.traits[ix].score * 100
        });
      }
    }

    return advices;
  };

  getIndexOfObject = (array, object) => {
    return array.findIndex(obj => obj.unique_code === object.unique_code);
  };

  filterChange = (selectedBrand, price) => {
    let filteredList = this.state.cartItems;

    filteredList = this.filterItemsByBrands(
      filteredList,
      selectedBrand.unique_code === "SelectedBrands"
        ? this.state.brands
        : [selectedBrand]
    );
    filteredList = this.filterItemsByPrice(filteredList, parseFloat(price));

    this.setState({
      filteredItems: filteredList
    });
  };

  filterItemsByBrands = (items, brands) => {
    let filteredItems = [];
    const allBrandsArr = brands.filter(b => b.unique_code === "AllBrands");
    if (allBrandsArr.length !== 0) {
      filteredItems.push(
        ...this.filterItemsByBrand(items, allBrandsArr[0].unique_code)
      );
    } else {
      brands.forEach(brand => {
        filteredItems.push(
          ...this.filterItemsByBrand(items, brand.unique_code)
        );
      });
    }

    return filteredItems;
  };

  onOptionSelectClick = option => {
    if (option === "filter") {
      this.refs.filterModal.open();
    } else if (option === "cart") {
      this.refs.cartModal.open();
    }
  };

  filterItemsByBrand = (items, unique_code) => {
    let filteredList = items;
    filteredList = filteredList.filter(item => {
      if (unique_code === "AllBrands") {
        let contains = false;
        if (this.state.brands) {
          for (const brandIx in this.state.brands) {
            if (
              this.state.brands[brandIx].unique_code === item.brand.unique_code
            ) {
              contains = true;
              break;
            }
          }
        }

        return contains;
      } else {
        if (unique_code === item.brand.unique_code) {
          return true;
        } else {
          return false;
        }
      }
    });

    return filteredList;
  };

  filterItemsByPrice = (items, price) => {
    let filteredList = items;

    filteredList = filteredList.filter(item => {
      if (price === 1) {
        return item.price <= 10;
      } else if (price === 2) {
        return item.price > 10 && item.price <= 20;
      } else if (price === 3) {
        return item.price > 20;
      } else {
        return true;
      }
    });

    return filteredList;
  };

  filterItemsById = (items, id) => {
    let filteredList = items;

    filteredList = filteredList.filter(item => {
      return item.id === id;
    });

    return filteredList;
  };

  addToCheckoutCart = id => {
    let checkoutItems = this.state.checkoutItems;
    let newItem = this.filterItemsById(this.state.filteredItems, id);

    let index = checkoutItems.findIndex(item => item[0].id === id);

    if (index === -1) {
      checkoutItems.push(newItem);

      this.setState({
        checkoutItems: checkoutItems,
        totalPoints: this.getTotalPoints(checkoutItems)
      });
    } else {
      let amount = newItem[0].amount++;
      this.setState({
        checkoutItems: this.state.checkoutItems.map(item =>
          item.id === id ? { ...item, amount } : item
        ),
        totalPoints: this.getTotalPoints(checkoutItems)
      });
    }

    this.setState({ showAddedToCartSnackbar: true });
  };

  getTotalPoints = checkoutItems => {
    let totalPoints = 0;

    checkoutItems.forEach(item => {
      if (item[0].points > totalPoints) {
        totalPoints = item[0].points;
      }
    });

    return totalPoints;
  };

  removeFromCheckoutCart = id => {
    let checkoutItems = this.state.checkoutItems;

    let index = checkoutItems.findIndex(item => item[0].id === id);
    let item = checkoutItems[index];

    if (item[0].amount > 1) {
      let amount = item[0].amount--;
      this.setState({
        checkoutItems: this.state.checkoutItems.map(item =>
          item.id === id ? { ...item, amount } : item
        ),
        totalPoints: this.getTotalPoints(checkoutItems)
      });
    } else {
      checkoutItems.splice(index, 1);

      this.setState({
        checkoutItems: checkoutItems,
        totalPoints: this.getTotalPoints(checkoutItems)
      });
    }
  };

  getNumberOfItems = checkoutItems => {
    let c = 0;
    if (checkoutItems) {
      for (let itemsIx in checkoutItems) {
        if (checkoutItems[itemsIx].length > 0) {
          c += checkoutItems[itemsIx][0].amount;
        }
      }
    }

    return c;
  };

  renderCartModal = () => {
    const checkoutCartHeader = (
      <Grid container alignItems="flex-start" justify="space-between">
        <Grid item xs={6} sm={3} className="text-left">
          <span className="modal-header-text">
            {this.userAndCategory.user.user.first_name}’s cart
          </span>
        </Grid>
        <Grid item xs={6} sm={3} className="text-right">
          <span className="modal-header-text">
            {this.getNumberOfItems(this.state.checkoutItems)}{" "}
            {this.getNumberOfItems(this.state.checkoutItems) === 1
              ? "item"
              : "items"}
          </span>
        </Grid>
      </Grid>
    );

    return (
      <div className="retail-experience-modal">
        <PureModal
          header={checkoutCartHeader}
          footer=" "
          onClose={() => {
            return true;
          }}
          ref="cartModal"
        >
          <Grid
            className="modal-body-background"
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <CheckoutCart
                checkoutItems={this.state.checkoutItems}
                careType={this.userAndCategory.category.unique_code}
                addItemCallback={this.addToCheckoutCart}
                removeItemCallback={this.removeFromCheckoutCart}
                totalPoints={this.state.totalPoints}
                name={this.props.name}
                retailer={this.state.cartHeaderImage}
                onCheckout={this.onCheckout}
                user={this.userAndCategory.user}
                category={this.userAndCategory.category}
              />
            </Grid>
          </Grid>
        </PureModal>
      </div>
    );
  };

  renderFilterModal = () => {
    const { brands, selectedBrand, price } = this.state;

    let Options = brands,
      Item = function(X) {
        return (
          <option key={X.unique_code} value={X.unique_code}>
            {X.name}
          </option>
        );
      };

    const priceButtons = [
      { text: "All", value: 0 },
      { text: "$", value: 1 },
      { text: "$$", value: 2 },
      { text: "$$$", value: 3 }
    ];

    return (
      <div className="retail-experience-modal">
        <PureModal
          header={<span className="modal-header-text text-center">Filter</span>}
          footer=" "
          onClose={() => {
            return true;
          }}
          ref="filterModal"
        >
          {brands && selectedBrand && (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              className="modal-body-background"
            >
              <Grid item xs={3} className="filters-label-text">
                BRAND
              </Grid>
              <Grid item xs={9} className="options-brand">
                <select
                  value={selectedBrand.unique_code}
                  onChange={this.handleOptionChange}
                >
                  {Options.map(Item)}
                </select>
              </Grid>

              <Grid item xs={3} className="filters-label-text mt-2">
                PRICE
              </Grid>
              <Grid item xs={9} className="price mt-2">
                {priceButtons.map(key => (
                  <button
                    key={key.value}
                    onClick={() => {
                      this.handlePriceChange(key.value);
                    }}
                    className={price === key.value ? "selected" : "unselected"}
                  >
                    {key.text}
                  </button>
                ))}
              </Grid>
            </Grid>
          )}
        </PureModal>
      </div>
    );
  };

  handleOptionChange = e => {
    let index = this.getIndexOfObject(this.state.brands, e.target.value);
    let brand = this.state.brands[index];

    this.setState(
      {
        selectedBrand: brand
      },
      () => {
        this.filterChange(this.state.selectedBrand, this.state.price);
      }
    );
  };

  getIndexOfObject = (array, value) => {
    return array.findIndex(obj => obj.unique_code === value);
  };

  handlePriceChange = value => {
    this.setState(
      {
        price: value
      },
      () => {
        this.filterChange(this.state.selectedBrand, this.state.price);
      }
    );
  };

  render() {
    return (
      <div>
        {this.renderFilterModal()}
        {this.renderCartModal()}

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.showAddedToCartSnackbar}
          key={"addedSnackbar"}
          onClose={() => this.setState({ showAddedToCartSnackbar: false })}
          message="Added to shopping cart!"
          autoHideDuration={3000}
        />

        {this.userAndCategory.user && this.userAndCategory.category && (
          <RetailExperienceModal
            name={this.userAndCategory.user.user.first_name}
            open={this.state.openModal}
            closeModalCallback={() => this.setState({ openModal: false })}
            careText={this.userAndCategory.category.name}
          />
        )}
        {this.userAndCategory.category && this.state.loaded === true && (
          <Header
            showTitle
            showStepper
            showNavigation
            activeStep={2}
            type={this.userAndCategory.category.name}
            showShoppingSection
            cartHeaderImage={this.state.cartHeaderImage}
            checkoutItems={this.state.checkoutItems}
            onOptionSelectClick={this.onOptionSelectClick}
          />
        )}

        {this.state.loaded === true && (
          <div className="retail-experience-cart-container">
            {/*<CartHeader
              image={this.state.cartHeaderImage}
              type={this.userAndCategory.category.name}
              optionSelectClick={this.onOptionSelectClick}
              ref={this.cartHeaderRef}
              checkoutItems={this.state.checkoutItems}
            />*/}
            <Grid container>
              <Grid item xs={12} md={8}>
                <Grid container>
                  {this.state.filteredItems.map(
                    (item, index) =>
                      item.image && (
                        <Grid key={index} item xs={6} sm={3} md={3}>
                          <CartItem
                            index={index}
                            key={item.id}
                            id={item.id}
                            points={item.points}
                            brand={item.brand}
                            description={item.name}
                            goodFor={this.makeitemAdvices(item)}
                            image={require(`../../../assets/images/product_images/${this.userAndCategory.category.unique_code}/${item.image}`)}
                            addToCart={this.addToCheckoutCart}
                          />
                        </Grid>
                      )
                  )}
                </Grid>
              </Grid>

              <Desktop>
                <Grid item md={4} className="desktop-cart-container mt-1">
                  <CheckoutCart
                    checkoutItems={this.state.checkoutItems}
                    careType={this.userAndCategory.category.unique_code}
                    addItemCallback={this.addToCheckoutCart}
                    removeItemCallback={this.removeFromCheckoutCart}
                    totalPoints={this.state.totalPoints}
                    name={this.props.name}
                    retailer={this.state.cartHeaderImage}
                    onCheckout={this.onCheckout}
                    user={this.userAndCategory.user}
                    category={this.userAndCategory.category}
                  />
                </Grid>
              </Desktop>
            </Grid>
          </div>
        )}
        <Desktop>
          <Footer hideFooter />
        </Desktop>
        {/* <Mobile>
          <Footer
            previousPageRoute={""}
            nextPageRoute={""}
            state={{
              user: this.props.location.state.user,
              category: this.props.location.state.category
            }}
          />
        </Mobile> */}
      </div>
    );
  }
}

export default withRouter(RetailExperienceCart);
