import React from "react";
import Header from "../../header/Header";
import Footer from "../../shared/Footer/Footer";
import { ROUTE_START_PAGE, ROUTE_DNA_PROFILE } from "../../../routes";
import "./Lifestyle.css";
import { withRouter } from "react-router-dom";
import ProfileItem from "../../shared/ProfileItem/ProfileItem";
import Grid from "@material-ui/core/Grid";
import APIClient from "../../../services/APIClient";
import { getUserAndCategory } from "../../../helpers/clientStorageHelper";
import { getCategoriesExplanations } from "../../../helpers/categoriesHelper.js";

class Lifestyle extends React.Component {
  userAndCategory = JSON.parse(getUserAndCategory());

  state = {
    iconClickedIx: 1
  };

  lifeStyleIconClicked(iconClickedIx) {
    this.setState({ iconClickedIx: iconClickedIx });
  }

  getTextForCategoryAndLifestyle(
    categoryCode,
    lifestyleCode,
    categoriesExplanations
  ) {
    for (const ix in categoriesExplanations) {
      if (
        categoriesExplanations[ix].lifestyle.unique_code === lifestyleCode &&
        categoriesExplanations[ix].category.unique_code === categoryCode
      ) {
        return categoriesExplanations[ix].text
          ? categoriesExplanations[ix].text.replace(
              "Precision Consumption Marketplace",
              "Precision Consumption Ecosystem"
            )
          : "";
      }
    }

    return undefined;
  }

  render() {
    // Lifestyle can be rendered only if all of the following are known: user, category, categoriesExplanations.
    if (this.userAndCategory == null) {
      this.props.history.push(ROUTE_START_PAGE);
      return null;
    }

    const user = this.userAndCategory.user;
    const category = this.userAndCategory.category;
    const categoriesExplanations = getCategoriesExplanations(
      category,
      this.userAndCategory.categoriesExplanations
    );
    if (user == null || category == null || categoriesExplanations == null) {
      this.props.history.push(ROUTE_START_PAGE);
      return null;
    }

    const type = category.name.toLowerCase();

    return (
      <div>
        <Header
          showTitle
          showStepper
          showNavigation
          activeStep={0}
          type={category.name}
          whiteBackground
        />

        <div className="lifestyle">
          <div className="lifestyle-intro">
            <span>
              An individuals’ diet, activity level, stress level, and
              environmental factors such as air pollution or UV indices impact
              their nutritional, skin, and hair and scalp health.
            </span>
          </div>
          <div className="lifestyle-section">
            <Grid container justify="center">
              <div className="lifestyle-icons-intro">
                <span>
                  Tap each of {user.user.first_name}’s lifestyle attributes to
                  learn how they affect{" "}
                  {user.basic_info.gender === 0 ? "his" : "her"} {type} product
                  recommendations.
                </span>
              </div>
            </Grid>
            <div className="lifestyle-icons">
              <Grid container justify="center">
                {user.lifestyle.map((userLifestyle, ix) => (
                  <div
                    key={ix}
                    className={
                      "lifestyle-icon " +
                      (this.state.iconClickedIx === ix + 1
                        ? "lifestyle-icon-selected"
                        : "lifestyle-icon-unselected")
                    }
                    onClick={() => this.lifeStyleIconClicked(ix + 1)}
                  >
                    <ProfileItem
                      imageUrl={`url(${APIClient.getApiUrl()}/static/${
                        userLifestyle.unique_code
                      }.png)`}
                      text={userLifestyle.name}
                    />
                  </div>
                ))}
              </Grid>
            </div>
            <div className="lifestyle-text-container">
              <Grid container justify="center">
                <div className="lifestyle-bg-image">
                  <img
                    src={`${APIClient.getApiUrl()}/static/${
                      user.lifestyle[this.state.iconClickedIx - 1].unique_code
                    }_text.png`}
                    alt="lifestyle_text_image"
                  />
                </div>

                <div className={"lifestyle-text lifestyle-explanation"}>
                  <div>
                    <div className="lifestyle-explanation-type">
                      <span className="bold">
                        {user.lifestyle[this.state.iconClickedIx - 1].type}
                      </span>
                    </div>
                    <div className="lifestyle-explanation-name">
                      <span className="bold">
                        {user.lifestyle[this.state.iconClickedIx - 1].name}
                      </span>
                    </div>
                    <div
                      className="lifestyle-explanation-desc"
                      dangerouslySetInnerHTML={{
                        __html: this.getTextForCategoryAndLifestyle(
                          category.unique_code,
                          user.lifestyle[this.state.iconClickedIx - 1]
                            .unique_code,
                          categoriesExplanations
                        )
                      }}
                    ></div>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </div>

        <Footer
          nextPageTitle="DNA Profile"
          previousPageTitle="Select a Focus Area"
          previousPageRoute={ROUTE_START_PAGE}
          nextPageRoute={ROUTE_DNA_PROFILE}
        />
      </div>
    );
  }
}

export default withRouter(Lifestyle);
