import React from "react";
import Footer from "../../shared/Footer/Footer";
import Header from "../../header/Header";
import {
  ROUTE_DNA_PROFILE,
  ROUTE_RETAIL_EXPERIENCE_CART,
  ROUTE_START_PAGE
} from "../../../routes";
import "./RetailExperience.css";
import APIClient from "../../../services/APIClient";
import { clearShoppingCart } from "../../../helpers/clientStorageHelper";
import { getUserAndCategory } from "../../../helpers/clientStorageHelper";
import { Redirect } from "react-router-dom";

export default class RetailExperience extends React.Component {
  userAndCategory = JSON.parse(getUserAndCategory());

  default_brand_selected = { unique_code: "AllBrands", name: "All Brands" };

  state = {
    brands: [this.default_brand_selected],
    selectedRetailer: "Retailer A",
    selectedRetailerImage: "retailer_a.png",
    selectedPrice: 0,
    selectedBrands: [this.default_brand_selected]
  };

  componentDidMount = () => {
    if (this.userAndCategory == null) {
      return;
    }

    const user = this.userAndCategory.user;
    const category = this.userAndCategory.category;

    if (user == null || category == null) {
      this.props.history.push(ROUTE_START_PAGE);
    } else {
      clearShoppingCart(user.user.first_name + "-" + category.unique_code);
      APIClient.getRecommendedBrand(
        user.profile.profile_id,
        category.unique_code
      )
        .then(response => {
          const brands = this.state.brands;
          for (const ix in response) {
            brands.push(response[ix]);
          }
          this.setState({ brands: brands });
        })
        .catch(err => console.log(err));
    }
  };

  handleRetailerSelect = retailer => {
    this.setState({
      selectedRetailer: retailer.text,
      selectedRetailerImage: retailer.image
    });
  };

  handlePriceSelect = value => {
    this.setState({
      selectedPrice: value
    });
  };

  handleBrandSelect = brand => {
    let selectedBrands = this.state.selectedBrands;

    if (this.getIndexOfObject(selectedBrands, brand) === -1) {
      if (brand.unique_code === this.default_brand_selected.unique_code) {
        selectedBrands = [];
      } else {
        let index = this.getIndexOfObject(
          selectedBrands,
          this.default_brand_selected
        );
        if (index !== -1) {
          selectedBrands.splice(index, 1);
        }
      }

      selectedBrands.push(brand);
    } else {
      let index = this.getIndexOfObject(selectedBrands, brand);
      selectedBrands.splice(index, 1);
    }

    if (selectedBrands.length === 0) {
      selectedBrands.push(this.default_brand_selected);
    }

    this.setState({
      selectedBrands
    });
  };

  getIndexOfObject = (array, object) => {
    return array.findIndex(obj => obj.unique_code === object.unique_code);
  };

  getBrandsFilter = () => {
    if (
      this.state.selectedBrands.length === 1 &&
      this.state.selectedBrands[0].unique_code === "AllBrands"
    ) {
      return this.state.brands;
    } else if (this.state.selectedBrands.length > 1) {
      const brands = this.state.selectedBrands;
      const selectedBrandsObj = {
        unique_code: "SelectedBrands",
        name: "Selected Brands"
      };
      if (this.getIndexOfObject(brands, selectedBrandsObj) === -1) {
        brands.splice(0, 0, selectedBrandsObj);
      }
      return brands;
    } else {
      return this.state.selectedBrands;
    }
  };

  render() {
    if (this.userAndCategory == null) {
      return <Redirect to={ROUTE_START_PAGE} />;
    }

    const user = this.userAndCategory.user;
    const category = this.userAndCategory.category;

    const retailers = [
      { value: 0, text: "Retailer A", image: "retailer_a.png" },
      { value: 1, text: "Retailer B", image: "retailer_b.png" }
    ];

    const priceValues = [
      { value: 0, text: "All Prices", subtitle: "" },
      { value: 1, text: "$", subtitle: "Less than $10" },
      { value: 2, text: "$$", subtitle: "$10 - $20" },
      { value: 3, text: "$$$", subtitle: "More than $20" }
    ];

    return (
      <div>
        {category && (
          <Header
            showTitle
            showStepper
            showNavigation
            activeStep={2}
            type={category.name}
          />
        )}
        <div className="retail-experience-content center">
          <div className="retail-experience-intro">
            Choose an <span className="bold">online retailer</span> where{" "}
            {user.user.first_name} will shop for {category.name.toLowerCase()}{" "}
            products.
          </div>

          <div className="retail-experience-retailers center">
            <div className="inline-block">
              {retailers.map((key, ix) => (
                <div className="inline-block" key={ix}>
                  <button
                    key={`retailer-${key.value}`}
                    className={`retail-experience-product-category font-18 ${
                      key.text === this.state.selectedRetailer ? "selected" : ""
                    }`}
                    style={
                      key.image !== ""
                        ? {
                            backgroundImage: `url(${require(`../../../assets/images/${key.image}`)})`
                          }
                        : {}
                    }
                    onClick={() => {
                      this.handleRetailerSelect(key);
                    }}
                  >
                    {key.image === "" && (
                      <span className="line-height-inherit text-v-center">
                        {key.text}
                      </span>
                    )}
                  </button>
                </div>
              ))}
              <div className="clear" />
            </div>
          </div>

          <div className="retail-experience-product-category-title">
            <span>
              Choose a {category.name.toLowerCase()}{" "}
              <span className="bold">product category</span> for{" "}
              {user.user.first_name} to shop for.
            </span>
          </div>

          <div className="retail-experience-product-categories center">
            {priceValues.map((key, ix) => (
              <div className="inline-block" key={ix}>
                <button
                  className={`retail-experience-product-category font-18 ${
                    key.value === this.state.selectedPrice ? "selected" : ""
                  }`}
                  onClick={() => {
                    this.handlePriceSelect(key.value);
                  }}
                >
                  <div className="retail-experience-product-category-text">
                    {key.text}
                  </div>
                  {key.subtitle !== "" && (
                    <div className="retail-experience-product-category-subtitle">
                      {key.subtitle}
                    </div>
                  )}
                </button>
              </div>
            ))}
            <div className="clear" />
          </div>

          <div className="retail-experience-product-brands">
            <span>
              Choose {category.name.toLowerCase()} product{" "}
              <span className="bold">brand or brands</span> for{" "}
              {user.user.first_name} to browse.
            </span>
          </div>

          <div className="retail-experience-product-categories center">
            {this.state.brands.map((brand, ix) => (
              <div className="inline-block" key={ix}>
                <button
                  key={`brand-${brand.unique_code}`}
                  className={`retail-experience-product-category font-18 ${
                    this.getIndexOfObject(this.state.selectedBrands, brand) !==
                    -1
                      ? "selected"
                      : ""
                  }`}
                  style={
                    brand.unique_code !==
                    this.default_brand_selected.unique_code
                      ? {
                          backgroundImage: `url(${require(`../../../assets/images/brandImages/${brand.unique_code}.png`)})`
                        }
                      : {}
                  }
                  onClick={() => {
                    this.handleBrandSelect(brand);
                  }}
                >
                  {brand.unique_code ===
                    this.default_brand_selected.unique_code && (
                    <span className="line-height-inherit text-v-center">
                      {brand.name}
                    </span>
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>
        <Footer
          nextPageTitle="Shop"
          previousPageTitle="View DNA Profile"
          previousPageRoute={ROUTE_DNA_PROFILE}
          nextPageRoute={ROUTE_RETAIL_EXPERIENCE_CART}
          meta={{
            price: this.state.selectedPrice,
            selectedBrands: this.getBrandsFilter(),
            retailerImage: this.state.selectedRetailerImage
          }}
          resetShoppingCart={this.props.name + "-" + this.props.type}
        />
      </div>
    );
  }
}
