import React from "react";
import "./ProfileCard.css";

export default class ProfileCard extends React.Component {
  handleClick = () => {
    if (this.props.disabled === true) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <div className="profile-card-container">
        <div
          className={
            "profile-card " +
            (this.props.profileImage ? " " : " custom-profile")
          }
          style={{
            backgroundImage: this.props.profileImage
              ? `url(${this.props.profileImage})`
              : "none"
          }}
          onClick={this.handleClick}
        >
          {!this.props.profileImage && (
            <div className="profile-card-image-text">
              <span>
                <u>Jamie</u> is a <u>26-year-old</u> who lives in{" "}
                <u>Chicago, Illinois, USA</u> and works as an{" "}
                <u>accounting manager</u>. She has <u>an active</u>
                lifestyle and abides by <u>an omnivore</u> diet.
              </span>
            </div>
          )}
          <div
            className={
              "profile-card-description " +
              (this.props.profileImage ? "" : "profile-card-description-custom")
            }
          >
            <div className={this.props.profileImage ? "profile-card-mt" : ""}>
              {this.props.profileText ? (
                <div className="float-left">{this.props.profileText}</div>
              ) : (
                <div className="profile-card-custom-text">Custom</div>
              )}

              <div className="profile-location float-left">
                {this.props.location ? (
                  <div className="profile-location-content">
                    <div className="profile-location-icon" />
                    <div className="profile-location-text">
                      {this.props.location}
                    </div>
                    <div className="clear" />
                  </div>
                ) : (
                  <div className="profile-location-content profile-location-button">
                    <button>Customize</button>
                  </div>
                )}
                <div className="clear" />
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
      </div>
    );
  }
}
