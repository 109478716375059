import React from "react";
import { benefitsLogo } from "../../../images/imagesList";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import OverlayModal from "components/shared/OverlayModal/OverlayModal";

class RetailExperienceModal extends React.Component {
  render() {
    return (
      <OverlayModal
        open={this.props.open}
        onClose={this.props.closeModalCallback}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} className="text-center">
            <img
              src={benefitsLogo}
              alt="benefits logo"
              width={36}
              height={36}
            ></img>
          </Grid>
          <Grid item xs={12} className="text-center bold main-title">
            Shop to Collect HealthyMe Points {this.props.name}
          </Grid>
          <Grid item xs={12} className="text-center modal-text-body-big">
            Check out with as many HealthyMe points as possible by adding
            high-ranking {this.props.careText} products to {this.props.name}’s
            shopping cart.
          </Grid>
          <Grid item xs={12}>
            <div className="line-small" />
          </Grid>
          <Grid item xs={12} className="text-center modal-text-body-small bold">
            What are HealthyMePoints?
          </Grid>
          <Grid item xs={12} className="text-center modal-text-body-small">
            HealthyMe points reflect the benefits an end-user will gain from a
            specific product (e.g., Pantene Pro-V Avocado Conditioner) in a
            given product category (e.g., shampoo and conditioner). HealthyMe
            points can be used to compare how different products can meet unique
            biological needs.
          </Grid>
          <Grid item xs={12} className="text-center modal-text-body-small bold">
            What determines a product’s HealthyMe point score?
          </Grid>
          <Grid item xs={12} className="text-center modal-text-body-small">
            AI-driven analysis of an end-user’s lifestyle assessment and DNA
            profile determines product recommendations and how a product will be
            uniquely scored for them.
          </Grid>
          <Grid item xs={12}>
            <hr className="line-big" />
          </Grid>
          <Grid item xs={12} className="text-center">
            <button
              className="modal-button"
              onClick={() => {
                this.props.closeModalCallback();
                this.setState({ open: false });
              }}
            >
              OK!
            </button>
          </Grid>
        </Grid>
      </OverlayModal>
    );
  }
}

export default withRouter(RetailExperienceModal);
