import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import {
  ROUTE_START_PAGE,
  ROUTE_RETAIL_EXPERIENCE,
  ROUTE_RETAIL_EXPERIENCE_CART,
  ROUTE_LIFESTYLE,
  ROUTE_DNA_PROFILE,
  ROUTE_BENEFITS
} from "./routes";
import StartPage from "./components/pages/StartPage/StartPage";
import RetailExperience from "../src/components/pages/RetailExperience/RetailExperience";
import RetailExperienceCart from "./components/pages/RetailExperienceCart/RetailExperienceCart";
import Lifestyle from "../src/components/pages/Lifestyle/Lifestyle";
import DnaProfile from "../src/components/pages/DnaProfile/DnaProfile";
import Benefits from "../src/components/pages/Benefits/Benefits";
import routeProps from "./helpers/RouteProps";

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route
              exact
              path={ROUTE_START_PAGE}
              component={routeProps(StartPage)}
            />
            <Route
              exact
              path={ROUTE_RETAIL_EXPERIENCE}
              component={routeProps(RetailExperience)}
            />
            <Route
              exact
              path={ROUTE_RETAIL_EXPERIENCE_CART}
              component={routeProps(RetailExperienceCart)}
            />
            <Route
              exact
              path={ROUTE_LIFESTYLE}
              component={routeProps(Lifestyle)}
            />
            <Route
              exact
              path={ROUTE_DNA_PROFILE}
              component={routeProps(DnaProfile)}
            />
            <Route
              exact
              path={ROUTE_BENEFITS}
              component={routeProps(Benefits)}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
