import React from "react";
import "./CheckoutItem.css";
import { Grid } from "@material-ui/core";

export default class CheckoutItem extends React.Component {
  state = {
    itemAmount: 1
  };
  handleAdd = () => {
    this.props.addCallback(this.props.id);
  };

  handleRemove = () => {
    this.props.removeCallback(this.props.id);
  };

  handleSeeDetails = () => {
    // ToDo
  };

  render() {
    return (
      <Grid
        className="checkout-item-container"
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={2} className="mr-1">
          <div>
            <img src={this.props.image} width="75" height="75" alt="amount" />
            <div className="checkout-items-amount">{this.props.amount}</div>
          </div>
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <div className="checkout-item-description">
                {this.props.description.length > 40
                  ? this.props.description.substr(0, 40) + "..."
                  : this.props.description}
              </div>
            </Grid>
            <Grid item xs={12} className="mt-4">
              <div className="checkout-item-options">
                <span
                  onClick={this.handleAdd}
                  className="item-option border-right"
                >
                  Add
                </span>
                <span onClick={this.handleRemove} className="item-option">
                  Remove
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
