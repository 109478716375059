import React, { Component } from "react";
import { benefitsLogo } from "../../images/imagesList";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import "./StayUpdatedModal.css";
import OverlayModal from "components/shared/OverlayModal/OverlayModal";
import { Grid } from "@material-ui/core";
import APIClient from "../../services/APIClient";

class StayUpdatedModal extends Component {
  state = {
    email: "",
    name: "",
    company: "",
    checkBoxOnePager: false,
    checkBoxCustomDemoAccess: false,
    checkBoxConsumerReport: false,
    checkBoxOrganizationInquiry: false,
    checkBoxIntroductionDeck: false,
    checkBoxMarketPlaceNewsletter: false,
    showError: false,
    errorText: "",
    nameError: false,
    emailError: false,
    showSuccess: false
  };

  handleClose = () => {
    this.props.closeModal();
  };

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleCompanyChange(e) {
    this.setState({ company: e.target.value });
  }

  submitData() {
    if (this.state.name === "") {
      this.setState({
        showError: true,
        nameError: true,
        errorText: "Please enter your name"
      });

      return;
    }

    if (/(.+)@(.+){2,}\.(.+){2,}/.test(this.state.email)) {
      // valid email
    } else {
      this.setState({
        showError: true,
        emailError: true,
        errorText: "Email address not valid!"
      });

      return;
    }

    this.setState({ showError: false, nameError: false, emailError: false });

    APIClient.contactSales({
      name: this.state.name,
      email: this.state.email,
      company: this.state.company,
      checkBoxOnePager: this.state.checkBoxOnePager,
      checkBoxCustomDemoAccess: this.state.checkBoxCustomDemoAccess,
      checkBoxConsumerReport: this.state.checkBoxConsumerReport,
      checkBoxOrganizationInquiry: this.state.checkBoxOrganizationInquiry,
      checkBoxIntroductionDeck: this.state.checkBoxIntroductionDeck,
      checkBoxMarketPlaceNewsletter: this.state.checkBoxMarketPlaceNewsletter
    })
      .then(response => {
        console.log(response);
        this.setState({ showSuccess: true, showError: false });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return (
      <OverlayModal open={this.props.open} onClose={this.handleClose}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} className="text-center">
            <img
              src={benefitsLogo}
              alt="benefits logo"
              width={36}
              height={36}
            ></img>
          </Grid>
          <Grid item xs={12} className="text-center bold main-title">
            Stay Updated & Get More Information
          </Grid>
          <Grid item xs={12} className="text-center modal-text-body-big">
            Provide your contact information and preferences, and we’ll send you
            more information. We promise we won’t use your contact information
            for anything else.
          </Grid>
          <Grid item xs={12}>
            <div className="line-small" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid
                item
                xs={12}
                className={
                  this.state.nameError ? "label-text label-red" : "label-text"
                }
              >
                Name
              </Grid>
              <Grid item xs={12}>
                <input
                  className="input-element"
                  value={this.state.name}
                  onChange={this.handleNameChange.bind(this)}
                  name="name"
                  type="text"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Input fields start */}
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={12} className="label-text">
                Company/Organization (Optional)
              </Grid>
              <Grid item xs={12}>
                <input
                  className="input-element"
                  value={this.state.company}
                  onChange={this.handleCompanyChange.bind(this)}
                  name="company"
                  type="text"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                xs={12}
                className={
                  this.state.emailError ? "label-text label-red" : "label-text"
                }
              >
                Email
              </Grid>
              <Grid item xs={12}>
                <input
                  className="input-element"
                  value={this.state.email}
                  onChange={this.handleEmailChange.bind(this)}
                  name="email"
                  type="email"
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Input fields end */}

          {/* Checkbox fields start */}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} className="label-text">
                Send me (please check all that apply)...
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={2}>
                    <div
                      onClick={() => {
                        this.setState({
                          checkBoxOnePager: !this.state.checkBoxOnePager
                        });
                      }}
                      className={
                        this.state.checkBoxOnePager
                          ? "checkbox-selected "
                          : " checkbox"
                      }
                    ></div>
                  </Grid>
                  <Grid item xs className="label-text">
                    Precision Consumption Ecosystem one-pager (Pdf)
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={2}>
                    <div
                      onClick={() => {
                        this.setState({
                          checkBoxCustomDemoAccess: !this.state
                            .checkBoxCustomDemoAccess
                        });
                      }}
                      className={
                        this.state.checkBoxCustomDemoAccess
                          ? "checkbox-selected "
                          : " checkbox"
                      }
                    ></div>
                  </Grid>
                  <Grid item xs className="label-text">
                    Precision Consumption Ecosystem premier and/or custom demo
                    access
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={2}>
                    <div
                      onClick={() => {
                        this.setState({
                          checkBoxConsumerReport: !this.state
                            .checkBoxConsumerReport
                        });
                      }}
                      className={
                        this.state.checkBoxConsumerReport
                          ? "checkbox-selected "
                          : " checkbox"
                      }
                    ></div>
                  </Grid>
                  <Grid item xs className="label-text">
                    Precision Consumer 2030 Report
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={2}>
                    <div
                      onClick={() => {
                        this.setState({
                          checkBoxOrganizationInquiry: !this.state
                            .checkBoxOrganizationInquiry
                        });
                      }}
                      className={
                        this.state.checkBoxOrganizationInquiry
                          ? "checkbox-selected "
                          : " checkbox"
                      }
                    ></div>
                  </Grid>
                  <Grid item xs className="label-text">
                    Potential partner organization inquiry
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={2}>
                    <div
                      onClick={() => {
                        this.setState({
                          checkBoxIntroductionDeck: !this.state
                            .checkBoxIntroductionDeck
                        });
                      }}
                      className={
                        this.state.checkBoxIntroductionDeck
                          ? "checkbox-selected "
                          : " checkbox"
                      }
                    ></div>
                  </Grid>
                  <Grid item xs className="label-text">
                    Precision Consumption Ecosystem introduction deck (PDF){" "}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={2}>
                    <div
                      onClick={() => {
                        this.setState({
                          checkBoxMarketPlaceNewsletter: !this.state
                            .checkBoxMarketPlaceNewsletter
                        });
                      }}
                      className={
                        this.state.checkBoxMarketPlaceNewsletter
                          ? "checkbox-selected "
                          : " checkbox"
                      }
                    ></div>
                  </Grid>
                  <Grid item xs className="label-text">
                    Precision Consumption Ecosystem Newsletter
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Checkbox fields end */}

          <Grid item xs={12}>
            {this.state.showError && (
              <div className="stay-updated-err">{this.state.errorText}</div>
            )}

            {this.state.showSuccess && (
              <div>Thank you, we will contact you soon!</div>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={this.state.showSuccess ? 12 : 6}>
                <Button
                  className="buttons-bottom modal-button-left"
                  color="primary"
                  onClick={this.handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              {this.state.showSuccess !== true && (
                <Grid item xs={6}>
                  <Button
                    className={
                      this.state.showError
                        ? "buttons-bottom button-right-error "
                        : "buttons-bottom button-right-normal "
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.submitData();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </OverlayModal>
    );
  }
}

export default withRouter(StayUpdatedModal);
