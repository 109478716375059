import React from "react";
import { benefitsLogo } from "../../images/imagesList";
import { withRouter } from "react-router-dom";
import StayUpdatedModal from "./StayUpdatedModal";
import VideoModal from "../shared/VideoPlayer/VideoPlayerModal";
import OverlayModal from "components/shared/OverlayModal/OverlayModal";
import { Grid } from "@material-ui/core";

class AboutModal extends React.Component {
  state = {
    openStayUpdatedModal: false,
    openVideoModal: false
  };

  handleClose = () => {
    this.props.closeModal();
  };

  handleCloseModal = () => {
    this.setState({
      openStayUpdatedModal: false,
      openVideoModal: false
    });
  };

  render() {
    return (
      <div>
        <StayUpdatedModal
          open={this.state.openStayUpdatedModal}
          closeModal={this.handleCloseModal}
        />
        <VideoModal
          open={this.state.openVideoModal}
          closeModal={this.handleCloseModal}
        />

        <OverlayModal open={this.props.open} onClose={this.handleClose}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} className="text-center">
              <img
                src={benefitsLogo}
                alt="benefits logo"
                width={36}
                height={36}
              ></img>
            </Grid>
            <Grid item xs={12} className="text-center bold main-title">
              About the Precision Consumption Ecosystem
            </Grid>
            <Grid item xs={12} className="modal-text-body-small">
              The <span className="bold">Precision Consumption Ecosystem</span>{" "}
              is a public-private ecosystem allowing users to access
              personalized wellness products and services by leveraging their
              biological, physiological, and behavioral data on a secure
              AI-powered platform.{" "}
            </Grid>

            <Grid item xs={12} className="modal-text-body-small">
              The initiative,{" "}
              <span className="bold">led by the World Economic Forum</span>,
              focuses on multi-stakeholder cooperation to help shape and
              accelerate the creation of a fair, trusted and responsible
              marketplace for consumer health and wellbeing data, products and
              services.
            </Grid>

            <Grid item xs={12} className="modal-text-body-small">
              <span className="bold">Retailers and brands benefit</span> by
              meeting the increasing personalization demands of their consumers
              and learn a lot more about their consumption habits in the
              process. Consumers benefit by being able to access highly
              personalized products and services based on their unique needs and
              characteristics with full control over their own personal data.{" "}
            </Grid>

            <Grid item xs={12} className="modal-text-body-small">
              <span className="bold">How does it work?</span> First, a consumer
              creates a precision consumption profile using their DNA and
              lifestyle data. Next, the AI-driven platform assesses their
              nutritional, fitness, and personal care needs and matches them
              with personalized products and services of participating brands
              and retailers on the company’s website or e-commerce platform.
              Consumers can use the platform to track how precision consumption
              has positively impacted their wellbeing over time, and brands can
              use this feedback to design more targeted and effective products.
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item xs={6} sm={4} className="text-center ">
                  <div className="modal-text-body-small bold mb-2">
                    Get more information
                  </div>
                </Grid>
                <Grid item xs={6} sm={4} className="text-center ">
                  <div className="modal-text-body-small bold mb-2">
                    See an overview of the process
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Grid
                  item
                  xs={6}
                  sm={4}
                  className="text-center modal-text-body-small bold"
                >
                  <button
                    className="modal-button"
                    onClick={() => {
                      this.setState({
                        openVideoModal: true
                      });
                      this.props.closeModal();
                    }}
                  >
                    Watch the video
                  </button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  className="text-center modal-text-body-small bold"
                >
                  <button
                    className="modal-button"
                    onClick={() => {
                      this.setState({
                        openStayUpdatedModal: true
                      });
                      this.props.closeModal();
                    }}
                  >
                    Stay updated
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </OverlayModal>
      </div>
    );
  }
}

export default withRouter(AboutModal);
