import React from "react";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import Swiper from "react-id-swiper";
import CaruselItem from "./CaruselItem";
import "./Profiles.css";

export default class Profiles extends React.Component {
  state = {
    initialSlide: 0
  };

  show(activeSlide) {
    this.setState({ initialSlide: activeSlide });
    this.refs.modal.open();
  }

  getCategoriesAvailableToUser(user) {
    for (const ix in this.props.categories) {
      if (this.props.categories[ix].user.first_name === user.user.first_name) {
        return this.props.categories[ix].categories;
      }
    }

    return [];
  }

  getCategoriesExplanationsForUser(user) {
    for (const ix in this.props.categories) {
      if (this.props.categories[ix].user.first_name === user.user.first_name) {
        return this.props.categories[ix].categories_explanations;
      }
    }

    return [];
  }

  render() {
    const params = {
      initialSlide: this.state.initialSlide,
      slidesPerView: "auto",
      centeredSlides: true,
      loop: false,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
      }
    };

    return (
      <div className="profile-container">
        <PureModal
          header=""
          footer=""
          onClose={() => {
            return true;
          }}
          ref="modal"
        >
          <Swiper {...params}>
            {this.props.predefinedUsers &&
              this.props.predefinedUsers.map((user, ix) => (
                <div key={ix}>
                  <CaruselItem
                    user={user}
                    categories={this.getCategoriesAvailableToUser(user)}
                    categoriesExplanations={this.getCategoriesExplanationsForUser(
                      user
                    )}
                  />
                </div>
              ))}
          </Swiper>
        </PureModal>
      </div>
    );
  }
}
