import React from "react";
import "./CartHeader.css";
import Grid from "@material-ui/core/Grid";
import { Mobile } from "helpers/Responsive";
import Badge from "@material-ui/core/Badge";

import {
  shopBag,
  shopBagSelected,
  filter,
  filterSelected
} from "../../../images/iconList";

export default class CartHeader extends React.Component {
  state = {
    optionSelected: undefined
  };

  handleOptionSelectClick = option => {
    if (this.state.optionSelected === option) {
      option = undefined;
    }

    this.setState({ optionSelected: option }, () => {
      this.props.optionSelectClick(option);
    });
  };

  modalClosedInParent = () => {
    this.setState({ optionSelected: undefined });
  };

  getNumberOfItems = checkoutItems => {
    let c = 0;
    if (checkoutItems) {
      for (let itemsIx in checkoutItems) {
        if (checkoutItems[itemsIx].length > 0) {
          c += checkoutItems[itemsIx][0].amount;
        }
      }
    }

    return c;
  };

  render() {
    return (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="cart-header-container"
      >
        <Grid item xs={6} sm={4}>
          <Grid container justify="flex-start" spacing={1}>
            <Grid item xs={12} sm={3}>
              <div
                className="cart-header-image"
                style={{
                  backgroundImage: `url(${require(`../../../assets/images/logos/${this.props.image}`)})`
                }}
              />
            </Grid>
            <Grid item xs={12} sm className="cart-header-text">
              {this.props.type}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sm>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid
              item
              onClick={() => {
                this.handleOptionSelectClick("filter");
              }}
            >
              <img
                className="option-icon-image"
                alt="filter"
                src={
                  this.state.optionSelected === "filter"
                    ? filterSelected
                    : filter
                }
                height="40"
                width="40"
              ></img>
            </Grid>
            <Mobile>
              <Grid
                item
                onClick={() => {
                  this.handleOptionSelectClick("cart");
                }}
                style={{ marginRight: "20px", marginTop: "-3px" }}
              >
                <Badge
                  badgeContent={this.getNumberOfItems(this.props.checkoutItems)}
                  color="primary"
                  overlap="circle"
                >
                  <img
                    className="option-icon-image"
                    alt="cart"
                    src={
                      this.state.optionSelected === "cart"
                        ? shopBagSelected
                        : shopBag
                    }
                    height="40"
                    width="40"
                  />
                </Badge>
              </Grid>
            </Mobile>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
