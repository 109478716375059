import React from "react";
import { ProgressBar } from "react-bootstrap";
import "./BenefitResult.css";

export default class BenefitResult extends React.Component {
  render() {
    let inactive = this.props.score > 0 ? "" : "inactive";
    return (
      <div className="benefit-result">
        <span
          className={"benefit-result-text benefit-result-text-left " + inactive}
        >
          {this.props.name}{" "}
        </span>
        <ProgressBar className="progress-bar-active" now={this.props.score} />
      </div>
    );
  }
}
