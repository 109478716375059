import React from "react";
import Button from "@material-ui/core/Button";
import "./FooterDesktop.css";
import { withRouter } from "react-router-dom";
import { clearShoppingCart } from "../../../helpers/clientStorageHelper";
import {
  getUserAndCategory,
  setUserAndCategory
} from "../../../helpers/clientStorageHelper";

class FooterDesktop extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-div">
          {this.props.showButtonCenter && (
            <div className="buttons-center">
              <span className="button-center-span">
                INTERESTED IN MORE INFORMATION?
              </span>
              <Button
                className="button-center"
                color="primary"
                onClick={() => {
                  if (this.props.onStayUpdated) {
                    this.props.onStayUpdated();
                  }
                }}
              >
                Learn More
              </Button>
            </div>
          )}

          <div className="buttons-right">
            {!this.props.hideLeftButton && (
              <Button
                className="button-left"
                color="primary"
                onClick={() => {
                  this.props.history.push({
                    pathname: this.props.previousPageRoute
                  });
                }}
              >
                <span className="bold">Previous:&nbsp;</span>
                <span> {this.props.previousPageTitle}</span>
              </Button>
            )}
            <Button
              className="button-right"
              variant="contained"
              color="primary"
              onClick={() => {
                if (this.props.resetShoppingCart) {
                  clearShoppingCart(this.props.resetShoppingCart);
                }

                if (this.props.meta && this.props.meta.price >= 0) {
                  // load state
                  const persistedState = JSON.parse(getUserAndCategory());
                  // update state
                  persistedState.price = this.props.meta.price;
                  persistedState.selectedBrands = this.props.meta.selectedBrands;
                  persistedState.retailerImage = this.props.meta.retailerImage;
                  // save state
                  setUserAndCategory(JSON.stringify(persistedState));
                }

                if (this.props.nextPageTitle === "Finish") {
                  if (this.props.onStayUpdated) {
                    this.props.onStayUpdated();
                  }
                } else {
                  this.props.history.push({
                    pathname: this.props.nextPageRoute
                  });
                }
              }}
            >
              Next: {this.props.nextPageTitle}
            </Button>
          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(FooterDesktop);
