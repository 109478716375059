import React from "react";
import "./DnaComponent.css";
import Grid from "@material-ui/core/Grid";

export default class DanDnaProfile extends React.Component {
  toggleReport = () => {
    if (this.props.callback) {
      this.props.callback();
    }
  };

  getRiskCss = () => {
    if (
      this.props.riskElevationLevel === 0 ||
      this.props.riskElevationLevel === 1
    ) {
      return "dna-component-risky";
    } else if (
      this.props.riskElevationLevel === 2 ||
      this.props.riskElevationLevel === 3
    ) {
      return "dna-component-moderate";
    } else {
      return "dna-component-normal";
    }
  };

  render() {
    return (
      <div className="dna-container">
        <div className="dna-component row">
          <div className="section-description col-6 col-md-4">
            <div className="title-container">
              <div className="title">{this.props.trait}</div>
              <div
                onClick={() => {
                  this.toggleReport();
                }}
                className="button"
              >
                {this.props.showReport ? "Hide report (-)" : "Show report (+)"}
              </div>
            </div>
            <div className="clear" />
          </div>
          <div className="dna-info col-2">
            <div className="info-container">
              <div className="score">{this.props.variationsNo}</div>
              <div className="text">Genetic Variants</div>
            </div>
          </div>
          <div className="dna-info col-2">
            <div className="info-container">
              <div className="score">
                {this.props.populationPerc}
                {"%"}
              </div>
              <div className="text">Population</div>
            </div>
          </div>
          <div className="col-6 col-md-4">
            <button className={this.getRiskCss()}>
              <span>{this.props.riskElevation}</span>
            </button>
          </div>
        </div>

        {this.props.showReport && (
          <div className="report">
            <div className="text">
              <Grid container>
                <Grid item md={6}>
                  <p className="bold">Summary</p>
                  <p>{this.props.populationIncidence}</p>
                </Grid>
                <Grid item md={6}>
                  <p className="bold">Recommendations</p>
                  <p>{this.props.recommendations}</p>
                </Grid>
              </Grid>
              <div className="percentage-sm">
                <p>
                  <span className="bold">Genetic Variants: </span>
                  <span>{this.props.variationsNo}</span>
                </p>
              </div>
              <div className="population-sm">
                <p>
                  <span className="bold">Population: </span>
                  <span>
                    {this.props.populationPerc}
                    {"%"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
