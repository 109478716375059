import React from 'react';
import { getStorageName, getStorageType } from './clientStorageHelper';

export function routeProps(Component) {
  return class RouteComponent extends React.Component {
    state = {
      name: getStorageName(),
      type: getStorageType(),
    };

    render() {
      return (
        <div>
          <Component
            {...this.props}
            type={this.state.type}
            name={this.state.name}
          />
        </div>
      );
    }
  };
}

export default routeProps;
