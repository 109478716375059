import axios from "axios";

// Instance containing config for all requests
// Unavailable from outside
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL + "/"
});

// Unique method to handle API errors accross whole app
// Could be moved somewhere else and check if Error Boundaries can be implemented on top of this
const handleErrors = error => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.debug("ERROR STATUS: ", error.response.status);
    console.debug(error.response);
    if (error.response.status === 401) {
      window.location = "/login";
    } else {
      throw new Error(error.response.status);
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.debug("No response");
    console.debug("Error request: ", error.request);
    throw new Error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.debug("Error message: ", error.message);
    throw new Error(error.message);
  }
  console.debug(error.config);
};

// Method for creating GET requests with implemented error handling
// Unavailable from outside
const axiosGET = async path => {
  let response = await axiosInstance
    .get(path)
    .catch(error => handleErrors(error));

  if (response) {
    return response.data;
  } else {
    return {};
  }
};

const axiosPOST = async (path, params) => {
  let response = await axiosInstance
    .post(path, params)
    .catch(error => handleErrors(error));

  if (response) {
    return response.data;
  } else {
    return {};
  }
};

// All methods available from outside
// Minimum of redundant code to create new method
export default {
  getApiUrl: () => {
    return process.env.REACT_APP_API_SERVER_URL;
  },
  getPredefinedUsersInfo: () => {
    return axiosGET(`users/`);
  },
  getRecommendedCategories: () => {
    return axiosGET(`recommendations/categories/`);
  },
  getRecommendedLifestyle: (profileId, categoryId) => {
    return axiosGET(`recommendations/lifestyle/${profileId}/${categoryId}/`);
  },
  getRecommendedBrand: (profileId, categoryId) => {
    return axiosGET(`recommendations/brand/${profileId}/${categoryId}/`);
  },
  getRecommendedProduct: (profileId, categoryId) => {
    return axiosGET(`recommendations/product/${profileId}/${categoryId}/`);
  },
  contactSales: params => {
    return axiosPOST("contact-sales/", params);
  }
};
