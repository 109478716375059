import React from "react";
import Header from "../../header/Header";
import DnaComponent from "./DnaComponent/DnaComponent";
import Footer from "../../shared/Footer/Footer";
import {
  ROUTE_LIFESTYLE,
  ROUTE_RETAIL_EXPERIENCE,
  ROUTE_START_PAGE
} from "../../../routes";
import "./DnaProfile.css";
import APIClient from "../../../services/APIClient";
import Grid from "@material-ui/core/Grid";
import { getUserAndCategory } from "../../../helpers/clientStorageHelper";

export default class DnaProfile extends React.Component {
  userAndCategory = JSON.parse(getUserAndCategory());

  state = {
    recommendations: undefined,
    collapseFlags: undefined
  };

  componentDidMount() {
    const user = this.userAndCategory.user;
    const category = this.userAndCategory.category;

    if (user == null || category == null) {
      this.props.history.push(ROUTE_START_PAGE);
    } else {
      APIClient.getRecommendedLifestyle(
        user.profile.profile_id,
        category.unique_code
      )
        .then(response => {
          const collapseFlags = [];
          for (const ix in response) {
            collapseFlags.push(false);
          }

          collapseFlags[0] = true;
          this.setState({ recommendations: response, collapseFlags });
        })
        .catch(err => console.log(err));
    }
  }

  getRisk(riskLevel) {
    if (riskLevel === 0) {
      return "Highly Elevated Risk";
    } else if (riskLevel === 1) {
      return "Elevated Risk";
    } else if (riskLevel === 2) {
      return "Slightly Elevated Risk";
    } else if (riskLevel === 3) {
      return "Typical";
    } else if (riskLevel === 4) {
      return "Low";
    } else {
      return "";
    }
  }

  toggleAllReports = flag => {
    const collapseFlags = this.state.collapseFlags;
    for (const ix in this.state.collapseFlags) {
      collapseFlags[ix] = flag;
    }
    this.setState({ collapseFlags });
  };

  toggleReport = ix => {
    const collapseFlags = this.state.collapseFlags;
    collapseFlags[ix] = !collapseFlags[ix];
    this.setState({ collapseFlags });
  };

  render() {
    const user = this.userAndCategory.user;
    const category = this.userAndCategory.category;

    return (
      <div>
        {category && (
          <Header
            showTitle
            showStepper
            showNavigation
            activeStep={1}
            type={category.name}
          />
        )}
        <div className="">
          <Grid container justify="center">
            <div className="dna-title-text center">
              <p>
                In combination with a lifestyle assessment,{" "}
                {user.user.first_name} uploads{" "}
                {user.basic_info.gender === "0" ? "his " : "her "}
                23andMe or AncestryDNA results to receive personalized reports
                that highlight
                {user.basic_info.gender === "0" ? " his " : " her "} unique{" "}
                {category.name.toLowerCase()} needs.
              </p>
            </div>
          </Grid>

          {this.state.recommendations && (
            <div>
              <div className="showOptions center">
                <Grid container justify="center">
                  <div
                    className="optionsButton"
                    onClick={() => {
                      this.toggleAllReports(true);
                    }}
                  >
                    Show All Reports
                  </div>
                  <div className="spacer">|</div>
                  <div className="numberOfReports">
                    {" "}
                    {this.state.recommendations.length} reports{" "}
                  </div>
                  <div className="spacer">|</div>
                  <div
                    className="optionsButton"
                    onClick={() => {
                      this.toggleAllReports(false);
                    }}
                  >
                    Hide All Reports
                  </div>
                  <div className="clear" />
                </Grid>
              </div>

              <Grid container justify="center">
                <div className="dna-components-container">
                  {this.state.recommendations.map((recommendation, ix) => (
                    <DnaComponent
                      key={ix}
                      showReport={this.state.collapseFlags[ix]}
                      callback={() => this.toggleReport(ix)}
                      variationsNo={recommendation.dna.no_genetic_variants}
                      populationPerc={recommendation.dna.population_percentage}
                      riskElevation={this.getRisk(
                        recommendation.dna.trait_risk_level
                      )}
                      riskElevationLevel={recommendation.dna.trait_risk_level}
                      populationIncidence={recommendation.summary}
                      recommendations={recommendation.recommendation}
                      trait={recommendation.dna.trait.name}
                    />
                  ))}
                </div>
              </Grid>
            </div>
          )}
        </div>
        <Footer
          nextPageTitle="Shopping Preferences"
          previousPageTitle="View Lifestyle"
          previousPageRoute={ROUTE_LIFESTYLE}
          nextPageRoute={ROUTE_RETAIL_EXPERIENCE}
        />
      </div>
    );
  }
}
